<template>
  <v-card
    flat
    class="mt-5"
  >
    <v-form ref="form">
      <div class="px-3">
        <v-card-text class="pt-5">
          <v-row>
            <v-col
              cols="12"
              sm="8"
              md="6"
              class="align-self-end"
            >
              <!-- current password -->
              <v-text-field
                v-model="currentPassword"
                :type="isCurrentPasswordVisible ? 'text' : 'password'"
                :append-icon="isCurrentPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="Current Password"
                outlined
                dense
                :rules="[validators.required]"
                @click:append="isCurrentPasswordVisible = !isCurrentPasswordVisible"
              ></v-text-field>

              <!-- new password -->
              <v-text-field
                v-model="newPassword"
                :type="isNewPasswordVisible ? 'text' : 'password'"
                :append-icon="isNewPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="New Password"
                outlined
                dense
                hint="Make sure it's at least 8 characters."
                persistent-hint
                class="mt-3"
                :rules="[validators.required]"
                @click:append="isNewPasswordVisible = !isNewPasswordVisible"
              ></v-text-field>

              <!-- confirm password -->
              <v-text-field
                v-model="cPassword"
                :type="isCPasswordVisible ? 'text' : 'password'"
                :append-icon="isCPasswordVisible ? icons.mdiEyeOffOutline:icons.mdiEyeOutline"
                label="Confirm New Password"
                outlined
                dense
                class="mt-2"
                :rules="[validators.required, validators.confirmedValidator(cPassword, newPassword)]"
                @click:append="isCPasswordVisible = !isCPasswordVisible"
              ></v-text-field>
            </v-col>

            <v-col
              cols="12"
              sm="4"
              md="6"
              class="d-none d-sm-flex justify-center position-relative"
            >
              <v-img
                contain
                max-width="170"
                src="@/assets/images/3d-characters/pose-m-1.png"
                class="security-character"
              ></v-img>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- action buttons -->
        <v-card-text class="d-flex justify-center">
          <v-btn
            color="secondary"
            outlined
            class="me-3 mt-3"
            @click="changePass"
          >
            Change Password
          </v-btn>
          <v-btn
            color="primary"
            class="mt-3"
            @click="cancelAction"
          >
            Cancel
          </v-btn>
        </v-card-text>
      </div>
    </v-form>
  </v-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { mdiKeyOutline, mdiLockOpenOutline, mdiEyeOffOutline, mdiEyeOutline } from '@mdi/js'
import { ref } from '@vue/composition-api'
// eslint-disable-next-line import/no-cycle
import { changePassword } from '@api'
import { error, success } from '@core/utils/toasted'
import { useRouter } from '@core/utils'
import { required, confirmedValidator } from '@core/utils/validation'
import useCryptoJs from '@/@core/utils/useCryptoJs'

export default {
  setup() {
    const isCurrentPasswordVisible = ref(false)
    const isNewPasswordVisible = ref(false)
    const isCPasswordVisible = ref(false)
    const currentPassword = ref(null)
    const newPassword = ref(null)
    const cPassword = ref(null)
    const form = ref(null)
    const { router } = useRouter()
    const { userData } = useCryptoJs()

    const changePass = async () => {
      if (form.value.validate()) {
        const response = await changePassword(userData.value.username, currentPassword.value, newPassword.value)
        if (response.ok) {
          success(response.message)
          router.push('/')
        } else {
          error(response.message.text)
        }
      }
    }

    const cancelAction = () => {
      form.value.reset()
      router.go(-1)
    }

    return {
      isCurrentPasswordVisible,
      isNewPasswordVisible,
      currentPassword,
      isCPasswordVisible,
      newPassword,
      cPassword,
      form,

      changePass,
      cancelAction,

      validators: {
        required,
        confirmedValidator,
      },

      icons: {
        mdiKeyOutline,
        mdiLockOpenOutline,
        mdiEyeOffOutline,
        mdiEyeOutline,
      },
    }
  },
}
</script>

<style lang="scss" scoped>
</style>
